import React from "react";

function TeamArea({ className }) {
  return (
    <section className={`team-area pt-115 pb-90 ${className || ""}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center mb-60 tg-heading-subheading animation-style3">
              <span className="sub-title tg-element-title">
                Professional Team
              </span>
              <h2 className="title tg-element-title">
                Professional Team Member
              </h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/german-castro.png`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">German Castro</a>
                </h2>
                <span>Founder & CEO</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/jeison-castro.png`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Jeison A. Castro</a>
                </h2>
                <span>Senior Manager</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/diana-castro.png`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Diana M. Castro</a>
                </h2>
                <span>Office Coordinator</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/deivis-castro.png`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Deivis Castro</a>
                </h2>
                <span>Sales Representative</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/cristian-villavicencio.png`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Cristian Villavicencio</a>
                </h2>
                <span>Advertisement</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/jonathan-aquino.png`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Jonathan Aquino</a>
                </h2>
                <span>Sales Representative</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/nayeli-sierra.png`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Nayeli Sierra</a>
                </h2>
                <span>Sales Representative</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-10">
            <div className="team-item">
              <div className="team-thumb">
                <a href="/team-details">
                  <img
                    src={require(`../../assets/img/team/juan-villavicencio.png`)}
                    alt=""
                  />
                </a>
                <div className="team-social">
                  <ul className="list-wrap">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="team-content">
                <h2 className="title">
                  <a href="/team-details">Juan P. Villavicencio</a>
                </h2>
                <span>Project Manager</span>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </section>
  );
}

export default TeamArea;

export interface City {
    name: string;
    lat: number;
    lng: number;
    county: string;
    contact: string;
    zips: string[];
  }
  
  const cities: City[] = [
    // Fairfield County (Extension 1)
    { name: 'Bethel', lat: 41.3712, lng: -73.4140, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06801'] },
    { name: 'Bridgeport', lat: 41.1865, lng: -73.1952, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06601', '06604', '06605', '06606', '06607', '06608', '06610'] },
    { name: 'Brookfield', lat: 41.4823, lng: -73.4097, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06804'] },
    { name: 'Danbury', lat: 41.3948, lng: -73.4540, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06810', '06811', '06813'] },
    { name: 'Darien', lat: 41.0772, lng: -73.4687, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06820'] },
    { name: 'Easton', lat: 41.2532, lng: -73.3037, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06612'] },
    { name: 'Fairfield', lat: 41.1408, lng: -73.2613, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06824', '06825'] },
    { name: 'Greenwich', lat: 41.0265, lng: -73.6285, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06830', '06831', '06870', '06878'] },
    { name: 'Monroe', lat: 41.3327, lng: -73.2073, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06468'] },
    { name: 'New Canaan', lat: 41.1468, lng: -73.4944, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06840'] },
    { name: 'New Fairfield', lat: 41.4665, lng: -73.4915, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06812'] },
    { name: 'Newtown', lat: 41.4140, lng: -73.3119, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06470'] },
    { name: 'Norwalk', lat: 41.1175, lng: -73.4079, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06850', '06851', '06854', '06855'] },
    { name: 'Redding', lat: 41.3026, lng: -73.3926, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06896'] },
    { name: 'Ridgefield', lat: 41.2815, lng: -73.4985, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06877', '06879'] },
    { name: 'Shelton', lat: 41.3165, lng: -73.0932, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06484'] },
    { name: 'Sherman', lat: 41.5884, lng: -73.5004, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06784'] },
    { name: 'Stamford', lat: 41.0534, lng: -73.5387, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06901', '06902', '06903', '06905', '06906', '06907'] },
    { name: 'Stratford', lat: 41.1845, lng: -73.1332, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06614', '06615'] },
    { name: 'Trumbull', lat: 41.2426, lng: -73.2007, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06611'] },
    { name: 'Weston', lat: 41.2007, lng: -73.3818, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06883'] },
    { name: 'Westport', lat: 41.1415, lng: -73.3579, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06880', '06881', '06888'] },
    { name: 'Wilton', lat: 41.1959, lng: -73.4379, county: 'Fairfield', contact: '1 (800) 838-8186 Ext 1', zips: ['06897'] },
  
    // New Haven County (Extension 2)
    { name: 'Ansonia', lat: 41.3437, lng: -73.0640, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06401'] },
    { name: 'Beacon Falls', lat: 41.4426, lng: -73.0645, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06403'] },
    { name: 'Bethany', lat: 41.4215, lng: -72.9962, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06524'] },
    { name: 'Branford', lat: 41.2795, lng: -72.8151, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06405'] },
    { name: 'Cheshire', lat: 41.4980, lng: -72.9016, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06410'] },
    { name: 'Derby', lat: 41.3207, lng: -73.0880, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06418'] },
    { name: 'East Haven', lat: 41.2762, lng: -72.8684, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06512'] },
    { name: 'Guilford', lat: 41.2887, lng: -72.6811, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06437'] },
    { name: 'Hamden', lat: 41.3959, lng: -72.8968, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06514', '06517', '06518'] },
    { name: 'Madison', lat: 41.2795, lng: -72.5987, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06443'] },
    { name: 'Meriden', lat: 41.5382, lng: -72.8070, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06450', '06451'] },
    { name: 'Middlebury', lat: 41.5287, lng: -73.1276, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06762'] },
    { name: 'Milford', lat: 41.2223, lng: -73.0565, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06460', '06461'] },
    { name: 'Naugatuck', lat: 41.4851, lng: -73.0509, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06770'] },
    { name: 'New Haven', lat: 41.3083, lng: -72.9279, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06510', '06511', '06512', '06513', '06515', '06519'] },
    { name: 'North Branford', lat: 41.3276, lng: -72.7695, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06471'] },
    { name: 'North Haven', lat: 41.3907, lng: -72.8598, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06473'] },
    { name: 'Orange', lat: 41.2901, lng: -73.0251, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06477'] },
    { name: 'Oxford', lat: 41.4332, lng: -73.1166, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06478'] },
    { name: 'Prospect', lat: 41.5023, lng: -72.9784, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06712'] },
    { name: 'Seymour', lat: 41.3965, lng: -73.0845, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06483'] },
    { name: 'Southbury', lat: 41.4793, lng: -73.2131, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06488'] },
    { name: 'Wallingford', lat: 41.4571, lng: -72.8231, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06492'] },
    { name: 'Waterbury', lat: 41.5585, lng: -73.0515, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06702', '06704', '06705', '06706', '06708', '06710'] },
    { name: 'West Haven', lat: 41.2710, lng: -72.9470, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06516'] },
    { name: 'Wolcott', lat: 41.6015, lng: -72.9865, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06716'] },
    { name: 'Woodbridge', lat: 41.3529, lng: -73.0151, county: 'New Haven', contact: '1 (800) 838-8186 Ext 2', zips: ['06525'] },
  
    // Hartford County (Extension 0)
    { name: 'Avon', lat: 41.8090, lng: -72.8300, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06001'] },
    { name: 'Berlin', lat: 41.6215, lng: -72.7456, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06037'] },
    { name: 'Bloomfield', lat: 41.8268, lng: -72.7306, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06002'] },
    { name: 'Bristol', lat: 41.6718, lng: -72.9493, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06010'] },
    { name: 'Burlington', lat: 41.7570, lng: -72.9643, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06013'] },
    { name: 'Canton', lat: 41.8243, lng: -72.8998, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06019'] },
    { name: 'East Granby', lat: 41.9410, lng: -72.7276, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06026'] },
    { name: 'East Hartford', lat: 41.7668, lng: -72.6511, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06108', '06118'] },
    { name: 'East Windsor', lat: 41.9068, lng: -72.6206, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06016', '06088'] },
    { name: 'Enfield', lat: 41.9762, lng: -72.5918, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06082', '06083'] },
    { name: 'Farmington', lat: 41.7198, lng: -72.8320, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06032', '06034'] },
    { name: 'Glastonbury', lat: 41.7123, lng: -72.6084, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06033', '06073'] },
    { name: 'Granby', lat: 41.9607, lng: -72.7915, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06035'] },
    { name: 'Hartford', lat: 41.7658, lng: -72.6734, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06103', '06105', '06106', '06112', '06114', '06120'] },
    { name: 'Hartland', lat: 41.9826, lng: -72.9367, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06027'] },
    { name: 'Manchester', lat: 41.7759, lng: -72.5215, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06040', '06042'] },
    { name: 'Marlborough', lat: 41.6314, lng: -72.4601, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06447'] },
    { name: 'New Britain', lat: 41.6612, lng: -72.7795, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06051', '06052', '06053'] },
    { name: 'Newington', lat: 41.6973, lng: -72.7236, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06111'] },
    { name: 'Plainville', lat: 41.6732, lng: -72.8625, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06062'] },
    { name: 'Rocky Hill', lat: 41.6643, lng: -72.6395, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06067'] },
    { name: 'Simsbury', lat: 41.8681, lng: -72.8026, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06070', '06089'] },
    { name: 'Southington', lat: 41.5965, lng: -72.8771, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06489'] },
    { name: 'South Windsor', lat: 41.8197, lng: -72.5823, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06074'] },
    { name: 'Suffield', lat: 41.9845, lng: -72.6509, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06078', '06080'] },
    { name: 'West Hartford', lat: 41.7621, lng: -72.7420, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06107', '06110', '06117', '06119'] },
    { name: 'Wethersfield', lat: 41.7146, lng: -72.6526, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06109'] },
    { name: 'Windsor', lat: 41.8526, lng: -72.6437, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06095'] },
    { name: 'Windsor Locks', lat: 41.9297, lng: -72.6454, county: 'Hartford', contact: '1 (800) 838-8186 Ext 0', zips: ['06096'] },
  
    // Litchfield County (Extension 0)
    { name: 'Barkhamsted', lat: 41.9214, lng: -73.0226, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06063'] },
    { name: 'Bethlehem', lat: 41.6398, lng: -73.2107, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06751'] },
    { name: 'Bridgewater', lat: 41.5357, lng: -73.3673, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06752'] },
    { name: 'Canaan', lat: 42.0290, lng: -73.3288, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06018'] },
    { name: 'Colebrook', lat: 41.9909, lng: -73.1096, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06021'] },
    { name: 'Cornwall', lat: 41.8454, lng: -73.3290, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06753'] },
    { name: 'Goshen', lat: 41.8312, lng: -73.2223, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06756'] },
    { name: 'Harwinton', lat: 41.7668, lng: -73.0590, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06791'] },
    { name: 'Kent', lat: 41.7243, lng: -73.4740, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06757'] },
    { name: 'Litchfield', lat: 41.7470, lng: -73.1887, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06759'] },
    { name: 'Morris', lat: 41.6834, lng: -73.1915, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06763'] },
    { name: 'New Hartford', lat: 41.8786, lng: -72.9740, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06057'] },
    { name: 'New Milford', lat: 41.5770, lng: -73.4084, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06776'] },
    { name: 'Norfolk', lat: 41.9906, lng: -73.1984, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06058'] },
    { name: 'North Canaan', lat: 42.0165, lng: -73.3260, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06024'] },
    { name: 'Plymouth', lat: 41.6793, lng: -73.0082, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06782'] },
    { name: 'Roxbury', lat: 41.5578, lng: -73.3080, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06783'] },
    { name: 'Salisbury', lat: 41.9831, lng: -73.4207, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06068'] },
    { name: 'Sharon', lat: 41.8791, lng: -73.4751, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06069'] },
    { name: 'Thomaston', lat: 41.6740, lng: -73.0737, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06787'] },
    { name: 'Torrington', lat: 41.8006, lng: -73.1212, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06790'] },
    { name: 'Warren', lat: 41.7462, lng: -73.3521, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06754'] },
    { name: 'Washington', lat: 41.6311, lng: -73.3107, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06793'] },
    { name: 'Watertown', lat: 41.6065, lng: -73.1187, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06795'] },
    { name: 'Winchester', lat: 41.9256, lng: -73.0672, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06098'] },
    { name: 'Woodbury', lat: 41.5448, lng: -73.2073, county: 'Litchfield', contact: '1 (800) 838-8186 Ext 0', zips: ['06798'] },
  
    // Middlesex County (Extension 0)
    { name: 'Chester', lat: 41.4008, lng: -72.4829, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06412'] },
    { name: 'Clinton', lat: 41.2784, lng: -72.5276, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06413'] },
    { name: 'Cromwell', lat: 41.5954, lng: -72.6457, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06416'] },
    { name: 'Deep River', lat: 41.3826, lng: -72.4418, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06417'] },
    { name: 'Durham', lat: 41.4634, lng: -72.6808, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06422'] },
    { name: 'East Haddam', lat: 41.5043, lng: -72.4620, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06423'] },
    { name: 'East Hampton', lat: 41.5757, lng: -72.5026, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06424'] },
    { name: 'Essex', lat: 41.3507, lng: -72.3954, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06426'] },
    { name: 'Haddam', lat: 41.4977, lng: -72.4873, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06438'] },
    { name: 'Killingworth', lat: 41.3618, lng: -72.5668, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06419'] },
    { name: 'Middlefield', lat: 41.5148, lng: -72.7105, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06455'] },
    { name: 'Middletown', lat: 41.5623, lng: -72.6506, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06457', '06459'] },
    { name: 'Old Saybrook', lat: 41.2932, lng: -72.3763, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06475'] },
    { name: 'Portland', lat: 41.5727, lng: -72.6308, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06480'] },
    { name: 'Westbrook', lat: 41.2953, lng: -72.4593, county: 'Middlesex', contact: '1 (800) 838-8186 Ext 0', zips: ['06498'] },
  
    // New London County (Extension 0)
    { name: 'Bozrah', lat: 41.5673, lng: -72.1579, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06334'] },
    { name: 'Colchester', lat: 41.5751, lng: -72.3323, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06415'] },
    { name: 'East Lyme', lat: 41.3537, lng: -72.2365, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06333', '06357'] },
    { name: 'Franklin', lat: 41.6045, lng: -72.1202, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06254'] },
    { name: 'Griswold', lat: 41.6034, lng: -71.9788, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06351'] },
    { name: 'Groton', lat: 41.3498, lng: -72.0780, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06340', '06349'] },
    { name: 'Lebanon', lat: 41.6298, lng: -72.2181, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06249'] },
    { name: 'Ledyard', lat: 41.4395, lng: -72.0000, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06335', '06339'] },
    { name: 'Lisbon', lat: 41.6000, lng: -71.9895, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06351'] },
    { name: 'Lyme', lat: 41.3690, lng: -72.3126, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06371'] },
    { name: 'Montville', lat: 41.4626, lng: -72.1065, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06353', '06382'] },
    { name: 'New London', lat: 41.3557, lng: -72.1003, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06320'] },
    { name: 'North Stonington', lat: 41.4384, lng: -71.8806, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06359'] },
    { name: 'Norwich', lat: 41.5243, lng: -72.0759, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06360', '06365', '06380'] },
    { name: 'Old Lyme', lat: 41.3151, lng: -72.3225, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06371'] },
    { name: 'Preston', lat: 41.5501, lng: -71.9690, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06365'] },
    { name: 'Salem', lat: 41.4889, lng: -72.2787, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06420'] },
    { name: 'Sprague', lat: 41.6257, lng: -72.0655, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06330'] },
    { name: 'Stonington', lat: 41.3356, lng: -71.9033, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06378'] },
    { name: 'Voluntown', lat: 41.5779, lng: -71.8638, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06384'] },
    { name: 'Waterford', lat: 41.3423, lng: -72.1616, county: 'New London', contact: '1 (800) 838-8186 Ext 0', zips: ['06385'] },
  
    // Tolland County (Extension 0)
    { name: 'Andover', lat: 41.7387, lng: -72.3670, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06232'] },
    { name: 'Bolton', lat: 41.7682, lng: -72.4460, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06043'] },
    { name: 'Columbia', lat: 41.7018, lng: -72.3061, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06237'] },
    { name: 'Coventry', lat: 41.7847, lng: -72.3365, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06238'] },
    { name: 'Ellington', lat: 41.9040, lng: -72.4736, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06029'] },
    { name: 'Hebron', lat: 41.6614, lng: -72.3679, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06248'] },
    { name: 'Mansfield', lat: 41.7654, lng: -72.2294, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06250'] },
    { name: 'Somers', lat: 41.9846, lng: -72.4459, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06071'] },
    { name: 'Stafford', lat: 41.9859, lng: -72.3050, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06075', '06076', '06077'] },
    { name: 'Tolland', lat: 41.8712, lng: -72.3682, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06084'] },
    { name: 'Union', lat: 41.9970, lng: -72.1510, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06076'] },
    { name: 'Vernon', lat: 41.8415, lng: -72.4638, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06066'] },
    { name: 'Willington', lat: 41.8791, lng: -72.2486, county: 'Tolland', contact: '1 (800) 838-8186 Ext 0', zips: ['06279'] },
  
    // Windham County (Extension 0)
    { name: 'Ashford', lat: 41.8731, lng: -72.1214, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06278'] },
    { name: 'Brooklyn', lat: 41.7887, lng: -71.9498, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06234'] },
    { name: 'Canterbury', lat: 41.7017, lng: -71.9912, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06331'] },
    { name: 'Chaplin', lat: 41.7898, lng: -72.1282, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06235'] },
    { name: 'Eastford', lat: 41.9001, lng: -72.0812, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06242'] },
    { name: 'Hampton', lat: 41.7820, lng: -72.0159, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06247'] },
    { name: 'Killingly', lat: 41.8287, lng: -71.8758, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06239', '06241'] },
    { name: 'Plainfield', lat: 41.6767, lng: -71.9031, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06374'] },
    { name: 'Pomfret', lat: 41.8988, lng: -71.9620, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06258'] },
    { name: 'Putnam', lat: 41.9156, lng: -71.9081, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06260'] },
    { name: 'Scotland', lat: 41.6968, lng: -72.0653, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06264'] },
    { name: 'Sterling', lat: 41.7340, lng: -71.8167, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06377'] },
    { name: 'Thompson', lat: 41.9773, lng: -71.8645, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06277'] },
    { name: 'Windham', lat: 41.6998, lng: -72.1577, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06280'] },
    { name: 'Woodstock', lat: 41.9482, lng: -71.9718, county: 'Windham', contact: '1 (800) 838-8186 Ext 0', zips: ['06281'] },
  ];
  
  export default cities;
  
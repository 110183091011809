import blogImg01 from '../../assets/img/blog/blog_img01.jpg';
import blogImg02 from '../../assets/img/blog/blog_img02.jpg';
import blogImg03 from '../../assets/img/blog/blog_img03.jpg';
import blogImg04 from '../../assets/img/blog/blog_img04.jpg';
import blogImg05 from '../../assets/img/blog/blog_img05.jpg';
import blogImg06 from '../../assets/img/blog/blog_img06.jpg';
import blogImg07 from '../../assets/img/blog/blog_img07.jpg';
import blogImg08 from '../../assets/img/blog/blog_img08.jpg';
import blogImg09 from '../../assets/img/blog/blog_img09.jpg';
import blogImg10 from '../../assets/img/blog/blog_img10.jpg';
import blogImg11 from '../../assets/img/blog/blog_img11.jpg';
import blogImg12 from '../../assets/img/blog/blog_img12.jpg';
import blogImg13 from '../../assets/img/blog/blog_img13.jpg';
import blogImg14 from '../../assets/img/blog/blog_img14.jpg';
import blogImg15 from '../../assets/img/blog/blog_img15.jpg';
import blogImg16 from '../../assets/img/blog/blog_img16.jpg';
import blogImg17 from '../../assets/img/blog/blog_img17.jpg';
import blogImg18 from '../../assets/img/blog/blog_img18.jpg';
import blogImg19 from '../../assets/img/blog/blog_img19.jpg';
import blogImg20 from '../../assets/img/blog/blog_img20.jpg';

const blogPosts = [
  [
    {
      id: 1,
      tag: "Roof Repair",
      title: "How to Repair Roof Shingles Blown Off by Storms",
      image: blogImg01,
      date: "15 Aug 2020",
      url: "./Roofing-Post",
    },
    {
      id: 2,
      tag: "Siding Replacement",
      title: "Siding Damage: How to Know When You Need a Replacement",
      image: blogImg02,
      date: "10 Sep 2020",
      url: "/siding-damage-replacement",
    },
    {
      id: 3,
      tag: "Roof Repair",
      title: "Quick and Effective Solutions for Roof Leaks",
      image: blogImg03,
      date: "05 Oct 2020",
      url: "/quick-effective-solutions-roof-leaks",
    },
    {
      id: 4,
      tag: "Storm Damage",
      title: "Protecting Your Home from Tree Damage During Storms",
      image: blogImg04,
      date: "20 Nov 2020",
      url: "/protecting-home-tree-damage-storms",
    },
    {
      id: 5,
      tag: "Roof Repair",
      title: "The Roof Repair Process: What You Need to Know",
      image: blogImg05,
      date: "15 Dec 2020",
      url: "/roof-repair-process",
    },
    {
      id: 6,
      tag: "Siding Repair",
      title: "How to Identify and Repair Hidden Siding Damage",
      image: blogImg06,
      date: "10 Jan 2021",
      url: "/identify-repair-hidden-siding-damage",
    },
  ],
  [
    {
      id: 7,
      tag: "Maintenance Tips",
      title: "Preventative Roof Maintenance to Avoid Leaks and Damage",
      image: blogImg07,
      date: "05 Feb 2021",
      url: "/preventative-roof-maintenance",
    },
    {
      id: 8,
      tag: "Siding Material",
      title: "Guide to Choosing the Best Siding Material for Your Home",
      image: blogImg08,
      date: "01 Mar 2021",
      url: "/choosing-best-siding-material",
    },
    {
      id: 9,
      tag: "Storm Damage",
      title: "Steps to Follow for Roof Repair After a Storm",
      image: blogImg09,
      date: "12 Apr 2021",
      url: "/roof-repair-after-storm",
    },
    {
      id: 10,
      tag: "Roof Repair",
      title: "Does Your Roof Have a Leak? Here's How to Fix It",
      image: blogImg10,
      date: "07 May 2021",
      url: "/roof-leak-fix",
    },
    {
      id: 11,
      tag: "Storm Damage",
      title: "Tips to Protect Your Home from Tree Damage During Storms",
      image: blogImg11,
      date: "15 Jun 2021",
      url: "/tips-protect-home-tree-damage",
    },
    {
      id: 12,
      tag: "Siding Protection",
      title: "The Importance of Siding in Protecting Your Home",
      image: blogImg12,
      date: "20 Jul 2021",
      url: "/importance-of-siding",
    },
  ],
  [
    {
      id: 13,
      tag: "Roof Inspection",
      title: "How to Spot Roof Problems Before It's Too Late",
      image: blogImg13,
      date: "18 Aug 2021",
      url: "/spot-roof-problems",
    },
    {
      id: 14,
      tag: "Roof Inspection",
      title: "Roof Inspection Guide: Keep Your Home Safe",
      image: blogImg14,
      date: "10 Sep 2021",
      url: "/roof-inspection-guide",
    },
    {
      id: 15,
      tag: "Siding Replacement",
      title: "When is the Right Time to Replace Your Siding?",
      image: blogImg15,
      date: "15 Oct 2021",
      url: "/replace-siding",
    },
    {
      id: 16,
      tag: "Winter Preparation",
      title: "Preparing Your Roof for Winter: Essential Tips",
      image: blogImg16,
      date: "05 Nov 2021",
      url: "/preparing-roof-winter",
    },
    {
      id: 17,
      tag: "Emergency Services",
      title: "How to Handle a Roofing Emergency After a Storm",
      image: blogImg17,
      date: "12 Dec 2021",
      url: "/handle-roofing-emergency",
    },
    {
      id: 18,
      tag: "Siding Quality",
      title: "Benefits of High-Quality Siding for Your Home",
      image: blogImg18,
      date: "18 Jan 2022",
      url: "/benefits-high-quality-siding",
    },
  ],
  [
    {
      id: 19,
      tag: "Roof Repair",
      title: "Roof Repair FAQ: Common Questions Answered",
      image: blogImg19,
      date: "10 Feb 2022",
      url: "/roof-repair-faq",
    },
    {
      id: 20,
      tag: "Roof Safety",
      title: "Roof Safety and Maintenance: What Every Homeowner Should Know",
      image: blogImg20,
      date: "15 Mar 2022",
      url: "/roof-safety-maintenance",
    },
  ],
  // More pages of blog posts can be added similarly
];

export default blogPosts;

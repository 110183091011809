import React from "react";
import HeaderThree from "../../Layouts/Headers/HeaderThree";
import FooterOne from "../../Layouts/Footers/FooterOne";
import BlogPost1 from "./MainWid";

function index() {
  return (
    <>
      <HeaderThree />
      
      <BlogPost1 />
      <FooterOne />
    </>
  );
}

export default index;
